import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import Main from "../components/Main";

import { PageHeader, SectionHeader } from "../components/Typo";
import Section from "../components/Section";
import Youtube from "../components/Youtube";
import VideoCarousel from "../components/VideoCarousel";
import { VideoModal } from "../components/VideoModal";

import { YoutubeLogo } from "../svg";

import YouTube from "simple-youtube-api";

import { FEATURED_VIDEO_ID } from "../featuredVideoId";

const VideoTop = styled.div`
  flex: 5;
  margin-right: 48px;

  ${media.lessThan("medium")`
  margin-right: 0px;
  margin-bottom: 24px;
  & .top-video {
    height: 50vw !important;
  }
  `}
`;

const PlaylistSection = styled(Section)`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 56px;

  ${media.lessThan("medium")`
    margin-top: 56px;
  `}
`;

const SubscribeButton = styled.button`
  display: block;
  margin: auto;
  margin-top: 24px;
  background-color: #d17174;
  border-radius: 3px;
  padding: 8px 24px;
  color: white;
  outline: 0;
  box-shadow: 0;
  border: 0;
  display: flex;
  align-items: center;
  height: 36px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: #c55a5d;
  }
`;

export default () => {
  const [state, setState] = React.useState(null);

  const [videoId, setVideoId] = React.useState(null);

  React.useEffect(() => {
    const yt = new YouTube("AIzaSyAb8HpPh9mGryfQBGA6ymVc7AnClLXojIs");

    Promise.all([
      yt
        .getPlaylist(
          "https://www.youtube.com/playlist?list=PLfRJRCjdGa86omYsC1gVb3ocJv38BlHsV"
        )
        .then((x) => x.getVideos(10)),
      yt
        .getPlaylist(
          "https://www.youtube.com/playlist?list=PLfRJRCjdGa8718p9BjHuCl91wqfYZtLzC"
        )
        .then((x) => x.getVideos(10)),
      yt
        .getPlaylist(
          "https://www.youtube.com/playlist?list=PLfRJRCjdGa84f_l9jg4sRv3AtgIZncKxy "
        )
        .then((x) => x.getVideos(10)),
    ]).then(([videoclips, live, covers]) => {
      setState({
        videoclips,
        live,
        covers,
        latest: [...videoclips, ...live, ...covers].sort(
          (a, b) => b.publishedAt - a.publishedAt
        )[0],
      });
    });
  }, []);

  return (
    <Main title="Kijk" path="/kijk">
      <VideoModal videoId={videoId} onClose={() => setVideoId(null)} />

      <PageHeader center title="Kijk" icon="video" />
      <Section
        noMargin
        style={{ maxWidth: 600, marginLeft: "auto", marginRight: "auto" }}
      >
        <VideoTop>
          {state && (
            <Youtube
              className="top-video"
              key={state.latest.id}
              width="100%"
              height="280px"
              videoId="PFC4XlRJ5Us"
            />
          )}
        </VideoTop>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/channel/UCY2vSCWit9DPXyxmHek_wBQ"
          style={{ textDecoration: "none" }}
        >
          <SubscribeButton>
            <YoutubeLogo width={24} style={{ marginRight: 8 }} /> Abonneren
          </SubscribeButton>
        </a>
      </Section>

      <PlaylistSection style={{ marginTop: 76 }}>
        <SectionHeader title="Videoclips" center noMargin />
        {state && (
          <VideoCarousel items={state.videoclips} onClick={setVideoId} />
        )}
      </PlaylistSection>

      <PlaylistSection>
        <SectionHeader title="Covers" center noMargin />

        {state && <VideoCarousel items={state.covers} onClick={setVideoId} />}
      </PlaylistSection>

      <PlaylistSection>
        <SectionHeader title="Live" center noMargin />

        {state && <VideoCarousel items={state.live} onClick={setVideoId} />}
      </PlaylistSection>
    </Main>
  );
};
